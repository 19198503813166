import { RadioGroup } from '@wearemotivated/design-system/redesign/components/RadioGroup';
import { type BaseOptionType } from '@wearemotivated/design-system/redesign/types';
import { type RadioGroupProps } from '@wearemotivated/design-system/redesign/components/RadioGroup';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import PricingCalculatorFormField from './PricingCalculatorFormField';

type PricingCalculatorRadioGroupProps<T extends BaseOptionType> = {
  title: string;
  tooltip?: string;
} & RadioGroupProps<T>;

export default function PricingCalculatorRadioGroup<T extends BaseOptionType>({
  title,
  tooltip,
  ...props
}: PricingCalculatorRadioGroupProps<T>) {
  return (
    <PricingCalculatorFormField title={title} tooltip={tooltip}>
      <RadioGroup
        {...props}
        optionLabelClassName={cn(
          'wf-text-rdsn-black wf-py-5 wf-leading-normal',
          props.optionLabelClassName,
        )}
      />
    </PricingCalculatorFormField>
  );
}
