'use client';

import { useEffect, useRef } from 'react';

type DynamicContentBlockVideoProps = {
  src: string;
  poster?: string;
};

export const DynamicContentBlockVideo = ({
  src,
  poster,
}: DynamicContentBlockVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              videoEl.play();
              observer.unobserve(videoEl);
            }
          });
        },
        {
          rootMargin: '100px',
        },
      );
      observer.observe(videoEl);
      return () => {
        observer.unobserve(videoEl);
      };
    }
  }, []);

  return (
    <video
      className="wf-absolute wf-inset-0 wf-z-[-1] wf-h-full wf-w-full wf-object-cover"
      src={src}
      preload="none"
      loop={true}
      playsInline={true}
      muted={true}
      ref={videoRef}
      {...(poster && { poster })}
    />
  );
};
