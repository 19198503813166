'use client';

import { useContext, useMemo } from 'react';

import { ConfigurationPresetContext } from './ConfigurationPresetProvider';

import type { AssetKey, FlagKey } from './types';

const useConfigurationPresetContext = () => {
  const context = useContext(ConfigurationPresetContext);

  if (!context) {
    throw new Error(
      'useConfigurationPresetContext must be used within a ConfigurationPresetContextProvider',
    );
  }

  return context;
};

export const useConfigurationPresetAssets = (assetKey: AssetKey) => {
  const { attributes } = useConfigurationPresetContext();

  return useMemo(() => {
    const asset = attributes.assets.find((asset) => asset.key === assetKey);

    if (asset) {
      const { desktop, mobile } = asset.value;

      return {
        desktop: {
          url: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${desktop?.data.attributes.url}`,
          alt:
            desktop?.data.attributes.alternativeText ||
            `${assetKey} desktop image`,
        },
        mobile: {
          url: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${mobile?.data[0].attributes.url}`,
          alt:
            mobile?.data[0].attributes.alternativeText ||
            `${assetKey} mobile image`,
        },
      };
    }

    return null;
  }, [assetKey, attributes.assets]);
};

export const useConfigurationPresetFlags = (flagKey: FlagKey) => {
  const { attributes } = useConfigurationPresetContext();

  return useMemo(() => {
    const flag = attributes.flags.find((flag) => flag.key === flagKey);

    return Boolean(flag?.value);
  }, [flagKey, attributes.flags]);
};

export const useConfigurationPresetSeoItems = () => {
  const { attributes } = useConfigurationPresetContext();

  return attributes.seoItems;
};
