import { type ReactNode } from 'react';
import { QuestionMarkCircle } from '@wearemotivated/design-system/redesign/icons/QuestionMarkCircle';
import { Tooltip } from '@wearemotivated/design-system/redesign/components/Tooltip';

type PricingCalculatorFormFieldProps = {
  title: string;
  tooltip?: string;
  children: ReactNode;
};

export default function PricingCalculatorFormField({
  title,
  tooltip,
  children,
}: PricingCalculatorFormFieldProps) {
  return (
    <div className="wf-space-y-4">
      <div className="wf-flex wf-items-center wf-gap-2">
        <h5 className="wf-text-rdsn-h5 wf-text-rdsn-black">{title}</h5>
        {tooltip && (
          <Tooltip content={<p className="max-w-[180px]">{tooltip}</p>}>
            <QuestionMarkCircle className="wf-text-rdsn-white" />
          </Tooltip>
        )}
      </div>
      {children}
    </div>
  );
}
