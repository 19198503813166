'use client';
import type { MouseEvent } from 'react';
import { useRouter } from 'next/navigation';
import type { ProductDataType } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';

type HeroRelatedProductCardProps = {
  data: ProductDataType;
  callToActionText?: string;
  learnMoreText?: string;
};

export const HeroRelatedProductCard = ({
  data,
  callToActionText,
  learnMoreText,
}: HeroRelatedProductCardProps) => {
  const router = useRouter();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (data.callToAction?.url && !(e.target instanceof HTMLAnchorElement)) {
      router.push(data.callToAction.url);
    }
  };

  return (
    <div
      className="wf-group wf-relative wf-w-[13.6875rem] wf-shrink-0 wf-font-gothic hover:wf-cursor-pointer lg:wf-w-[21rem]"
      onClick={handleClick}>
      <img
        src={data.image?.url}
        alt={data.image?.alt}
        className="wf-absolute wf-left-1/2 wf-top-0 wf-z-10 wf-max-w-[7.5rem] -wf-translate-x-1/2 -wf-translate-y-[40%] wf-object-contain wf-drop-shadow-[0_20px_20px_rgba(0,_0,_0,_0.3)] wf-transition-[transform_0.3s_ease,_filter_0.3s_ease] group-hover:-wf-translate-y-[65%] group-hover:wf-drop-shadow-[0_40px_30px_rgba(0,_0,_0,_0.3)]"
      />
      <div className="wf-rounded wf-border wf-border-rdsn-gray-400 wf-bg-rdsn-white wf-px-4 wf-pb-10 wf-pt-[8.1875rem] wf-text-rdsn-black wf-transition-transform group-hover:wf-scale-[1.02] group-hover:wf-border-rdsn-black lg:wf-pb-4">
        <div className="wf-space-y-2">
          <h3 className="wf-text-rdsn-prod">{data.name}</h3>
          <span className="wf-text-rdsn-prod-sub">{data.medicationName}</span>
        </div>
        <div className="wf-mt-6 wf-flex wf-gap-x-2.5 lg:wf-flex-row-reverse">
          <Button
            size="small"
            variant="primary"
            className="wf-grow wf-px-0"
            asChild>
            <a
              className="lg:wf-inline-flex lg:wf-gap-x-2"
              href={data.callToAction?.url ?? ''}>
              <span>
                {callToActionText?.replace('>', '').trim() ??
                  data.callToAction?.text}
              </span>
              {callToActionText?.includes('>') && (
                <ArrowRightIcon className="max-lg:wf-hidden" />
              )}
            </a>
          </Button>
          {learnMoreText && (
            <Button
              size="small"
              variant="secondary"
              className="wf-px-2.5 lg:wf-grow"
              asChild>
              <a href={data.productSlug ?? ''}>{learnMoreText}</a>
            </Button>
          )}
        </div>
        {data.safetyInformationUrl && (
          <div className="wf-mt-4 wf-flex lg:wf-justify-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="wf-inline-block wf-text-rdsn-cap wf-text-rdsn-gray-800 wf-underline lg:wf-text-rdsn-black"
              href={data.safetyInformationUrl}>
              Important safety information
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
