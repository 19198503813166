'use client';
import * as React from 'react';

import ResponsiveImage from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { FloatingImageType } from '@/redesign/api/strapi/types';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { fixLocalImage } from '@/redesign/lib/utils/media';

// breakpoints used by the parse function
// empty string is the base breakpoint
const POSITION_BREAKPOINTS = ['', 'sm', 'md', 'lg', 'xl', '2xl'];
const DEFAULT_UNIT = 'px';

const parseImageConfig = (
  config: FloatingImageType['imageConfig'],
  position: FloatingImageType['position'],
) => {
  // default values if position is set
  // setting top to 0 as it's expected to be 0 if nothing is set
  let values: Record<string, Record<string, any>> = {
    top: { '': 0 },
    ...(position === 'left' && { left: { '': 0 } }),
    ...(position === 'right' && { right: { '': 0 } }),
    width: { '': '300px' },
    height: { '': 'auto' },
  };

  const style: Record<string, string> = {};

  // parse config and group/sort values
  for (const key in config) {
    let [breakpoint, position] = key.split(':');
    if (!breakpoint && !position) {
      continue;
    }

    if (!position) {
      position = breakpoint;
      breakpoint = '';
    }

    if (!values[position]) {
      values[position] = {};
    }

    let value = config[key];
    // if the value is a number and doesn't contain a unit, add DEFAULT_UNIT
    if (typeof value === 'number' || /^\d+$/.test(value.trim())) {
      value = `${value}${DEFAULT_UNIT}`;
    }
    values[position][breakpoint] = value;
  }

  for (const positionValue in values) {
    let lastValue = null;

    for (const breakpoint of POSITION_BREAKPOINTS) {
      let currentValue = values[positionValue][breakpoint];

      // if value is undefined, use the last defined value
      if (currentValue === undefined && lastValue === null) {
        continue;
      }
      if (currentValue === undefined) {
        currentValue = lastValue;
      }
      // update lastValue with the current value
      if (currentValue !== undefined) {
        lastValue = currentValue;
      }

      const cssVarName = `--mv-${positionValue}${breakpoint ? `-${breakpoint}` : ''}`;
      style[cssVarName] = currentValue;
    }
  }

  return style;
};

export const FloatingImage = ({
  componentData: data,
}: {
  componentData: FloatingImageType;
}) => {
  const { imageConfig, image, position, visibilityMode } = data;

  return (
    <div className="wf-pointer-events-none wf-w-full wf-overflow-x-clip">
      <div className="wf-relative wf-w-full">
        <div
          className={cn(
            'wf-absolute wf-z-[1]',
            `wf-bottom-[var(--mv-bottom)] wf-left-[var(--mv-left)] wf-right-[var(--mv-right)] wf-top-[var(--mv-top)] sm:wf-bottom-[var(--mv-bottom-sm)] sm:wf-left-[var(--mv-left-sm)] sm:wf-right-[var(--mv-right-sm)] sm:wf-top-[var(--mv-top-sm)] md:wf-bottom-[var(--mv-bottom-md)] md:wf-left-[var(--mv-left-md)] md:wf-right-[var(--mv-right-md)] md:wf-top-[var(--mv-top-md)] lg:wf-bottom-[var(--mv-bottom-lg)] lg:wf-left-[var(--mv-left-lg)] lg:wf-right-[var(--mv-right-lg)] lg:wf-top-[var(--mv-top-lg)] xl:wf-bottom-[var(--mv-bottom-xl)] xl:wf-left-[var(--mv-left-xl)] xl:wf-right-[var(--mv-right-xl)] xl:wf-top-[var(--mv-top-xl)] 2xl:wf-bottom-[var(--mv-bottom-2xl)] 2xl:wf-left-[var(--mv-left-2xl)] 2xl:wf-right-[var(--mv-right-2xl)] 2xl:wf-top-[var(--mv-top-2xl)]`,
            visibilityMode === 'desktop-only' && 'max-sm:wf-hidden',
            visibilityMode === 'mobile-only' && 'sm:wf-hidden',
          )}
          style={parseImageConfig(imageConfig, position)}>
          <ResponsiveImage
            className={cn(
              'wf-object-contain',
              `wf-h-[var(--mv-height)] wf-w-[var(--mv-width)] sm:wf-h-[var(--mv-height-sm)] sm:wf-w-[var(--mv-width-sm)] md:wf-h-[var(--mv-height-md)] md:wf-w-[var(--mv-width-md)] lg:wf-h-[var(--mv-height-lg)] lg:wf-w-[var(--mv-width-lg)] xl:wf-h-[var(--mv-height-xl)] xl:wf-w-[var(--mv-width-xl)] 2xl:wf-h-[var(--mv-height-2xl)] 2xl:wf-w-[var(--mv-width-2xl)]`,
            )}
            {...fixLocalImage(image)}
          />
        </div>
      </div>
    </div>
  );
};
