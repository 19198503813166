'use client';
import { useRef, useState } from 'react';
import { TopBannerContentProps } from '@/redesign/components/product-page/TopBannerContent';
import { ProductMediaCarouselItem } from '@/redesign/components/product-page/ProductMediaCarouselItem';

type ProductMediaCarouselProps = {
  items: TopBannerContentProps['data']['carousel'];
};

export const ProductMediaCarousel = ({
  items = [],
}: ProductMediaCarouselProps) => {
  const [mainImageIndex, setMainImageIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const showItems = items.filter(
    (item) => item.video?.desktop.url || item.image?.desktop.url,
  );

  const handleThumbnailClick = (index: number) => () => {
    setMainImageIndex(index);
  };

  if (!showItems.length) {
    return null;
  }

  return (
    <>
      <div className="max-sm:wf-hidden sm:wf-aspect-[571/597] sm:wf-w-full">
        <ProductMediaCarouselItem data={showItems[mainImageIndex]} />
      </div>
      <div
        className="scrollbar-hide wf-flex wf-gap-x-2.5 wf-overflow-x-auto sm:wf-mt-[1.125rem] sm:wf-gap-x-2"
        ref={containerRef}>
        {showItems.map((item, i) => (
          <div
            key={item.id}
            className="wf-w-[15.625rem] wf-shrink-0 hover:wf-cursor-pointer sm:wf-h-20 sm:wf-w-20"
            onClick={handleThumbnailClick(i)}>
            <ProductMediaCarouselItem data={item} />
          </div>
        ))}
      </div>
    </>
  );
};
