'use client';
import * as React from 'react';
import { useConfigurationPresetSeoItems } from '@/app/configuration-preset/hooks';

interface StructuredDataProps {
  value: Array<Record<string, unknown>> | null;
}

const StructuredData = ({ value }: StructuredDataProps) => {
  const seoItems = useConfigurationPresetSeoItems();
  const scriptId = React.useId();

  return (
    <>
      {seoItems[0] && seoItems[0].seo?.structuredData ? (
        <script
          id={`sd-cp-${scriptId}`}
          key={`jsonld-cp-${scriptId}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(seoItems[0].seo?.structuredData, null, '\t'),
          }}
        />
      ) : null}

      {value ? (
        <script
          id={`sd-${scriptId}`}
          key={`jsonld-${scriptId}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(value, null, '\t'),
          }}
        />
      ) : null}
    </>
  );
};

export default StructuredData;
