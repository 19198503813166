'use client';

import * as React from 'react';
import ResponsiveImage from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { MarkdownText } from '@wearemotivated/design-system/redesign/components/MarkdownText';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { CheckmarkIcon } from '@wearemotivated/design-system/redesign/icons/Checkmark';

import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import MediaCarousel, {
  MediaCarouselItem,
} from '@/redesign/strapi-blocks/shared/MediaCarousel';
import { fixLocalImage } from '@/redesign/lib/utils/media';
import { HeroItemClean, LandingTopBanner } from '@strapi-schema';

import { TopBannerFeatures } from './TopBannerFeatures';

type DataType = {
  title?: string | undefined;
  description?: string | undefined;
  disclaimerText?: string | undefined | null;
  features: HeroItemClean[];
  id: number;
  __component: string;
  image: {
    desktop: { url: string; alt: string };
    mobile?: { url: string; alt: string };
  };
  callToAction: {
    text?: string | undefined;
    disclaimerText?: string | null;
    link: string;
    trackingAttributes: {
      id: number;
      name: string;
      customAttributes: { [x: string]: any };
    };
  };
  carousel: {
    items: MediaCarouselItem[];
  };
  mobileLayout?: LandingTopBanner['mobileLayout'];
};

type TopBannerProps = {
  componentData: DataType;
  slug?: string;
  className?: string;
  classNameImageContainer?: string;
};

export const TopBanner = ({
  componentData: {
    title,
    description,
    image,
    features,
    callToAction,
    carousel,
    mobileLayout,
  },
  className,
}: TopBannerProps) => {
  const isExpMobileLayout = [
    'exp-v1-pills-and-top-rating',
    'exp-v2-pills-and-bottom-rating',
  ].includes(mobileLayout ?? '');
  return (
    <div className="w-full wf-overflow-x-hidden">
      <div className="wf-relative wf-mx-auto wf-w-full wf-max-w-xl lg:wf-max-w-6xl">
        <div
          className={cn(
            'wf-absolute wf-z-[1] lg:wf-left-[-290px] lg:wf-right-[unset] lg:wf-top-[-70px]',
            !isExpMobileLayout
              ? 'wf-right-[-115px] wf-top-[50px] sm:wf-right-[-240px] sm:wf-top-[80px]'
              : 'max-lg:wf-right-2.5',
            mobileLayout === 'exp-v1-pills-and-top-rating' &&
              'max-lg:wf-top-[125px]',
            mobileLayout === 'exp-v2-pills-and-bottom-rating' &&
              'max-lg:wf-top-[110px]',
          )}>
          <ResponsiveImage
            className={cn(
              'wf-h-full wf-w-[300px] wf-max-w-[450px] wf-object-contain max-[360px]:wf-hidden',
              !isExpMobileLayout
                ? 'sm:wf-w-auto'
                : 'max-lg:wf-w-[82px] lg:wf-w-auto',
            )}
            minWidthBreakpoint="lg"
            {...fixLocalImage(image)}
          />
        </div>
        <div
          className={cn(
            `wf-relative wf-overflow-hidden wf-bg-rdsn-white wf-p-4 sm:wf-p-8 md:wf-overflow-visible lg:wf-px-0 lg:wf-py-15`,
            className,
          )}>
          <div
            className={cn(
              'wf-relative wf-flex wf-w-full wf-flex-col wf-items-center wf-justify-center lg:wf-flex-row lg:wf-items-center',
              ['wf-rounded-[5px]', 'wf-border', 'wf-border-rdsn-gray-200'].join(
                ` ${!isExpMobileLayout ? '' : 'lg:'}`,
              ),
            )}>
            <div
              className={cn(
                'wf-flex wf-w-full wf-flex-1 wf-justify-center wf-pb-11 lg:wf-w-1/2 lg:wf-pb-[90px] lg:wf-pl-32 lg:wf-pr-4 lg:wf-pt-36',
                !isExpMobileLayout ? 'wf-px-4 wf-pt-8' : 'wf-px-1 wf-pt-1',
              )}>
              <div className="wf-flex wf-w-full wf-max-w-[457px] wf-flex-col wf-items-start wf-text-rdsn-black">
                <div
                  className={cn(
                    'wf-mb-[1.375rem] wf-w-full wf-space-y-8 lg:wf-mb-14 lg:wf-space-y-10',
                    mobileLayout === 'exp-v1-pills-and-top-rating' &&
                      'max-lg:wf-space-y-5',
                  )}>
                  <h1
                    className={cn(
                      'wf-font-gothic wf-text-[28px] wf-font-light wf-leading-none -wf-tracking-[0.02rem] lg:wf-w-full lg:wf-text-rdsn-h1',
                      !isExpMobileLayout
                        ? 'max-lg:wf-max-w-[270px]'
                        : 'max-lg:wf-w-[105%]',
                    )}>
                    {title}
                  </h1>
                  <div
                    className={cn(
                      mobileLayout !== 'exp-v1-pills-and-top-rating'
                        ? 'wf-hidden'
                        : 'lg:wf-hidden',
                    )}>
                    <TopBannerFeatures
                      features={features}
                      mobileLayout={mobileLayout}
                    />
                  </div>
                  <MarkdownText
                    className="wf-w-full wf-text-rdsn-body lg:wf-pr-28"
                    components={{
                      ul: ({ children }) => (
                        <ul className="w-full max-lg:wf-pr-6 lg:wf-min-w-[337px]">
                          {children}
                        </ul>
                      ),
                      li: ({ children }) => (
                        <li className="wf-mb-2 wf-flex wf-gap-2 wf-border-b wf-border-rdsn-gray-200 wf-pb-2 wf-text-rdsn-body last-of-type:wf-mb-0 last-of-type:wf-border-b-0 last-of-type:wf-pb-0">
                          <div className="wf-flex wf-h-5 wf-w-5 wf-items-center wf-justify-center wf-rounded-full wf-bg-rdsn-black">
                            <CheckmarkIcon className="wf-h-[6.8px] wf-w-[9.4px] wf-text-rdsn-white" />
                          </div>
                          {children}
                        </li>
                      ),
                    }}>
                    {description ?? ''}
                  </MarkdownText>
                </div>
                <div
                  className={cn(
                    'wf-z-[2] wf-flex wf-w-full wf-flex-col wf-items-center wf-gap-y-4 wf-self-center',
                    isExpMobileLayout && 'max-lg:wf-gap-y-3',
                  )}>
                  <Button
                    size="xl"
                    variant="primary"
                    className={cn(
                      'wf-px-7 lg:wf-h-16',
                      !isExpMobileLayout ? 'wf-h-12' : 'wf-h-[3.25rem]',
                    )}
                    asChild>
                    <a className="wf-w-full" href={callToAction?.link || ''}>
                      {callToAction?.text}{' '}
                      <ArrowRightIcon className="wf-ml-2 wf-h-4 wf-w-4 wf-shrink-0" />
                    </a>
                  </Button>
                  {callToAction?.disclaimerText ? (
                    <span className="wf-text-center wf-font-gothic wf-text-rdsn-prod-sub wf-text-rdsn-black">
                      {callToAction?.disclaimerText}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={cn(
                'wf-flex wf-w-fit lg:wf-px-16 lg:wf-py-12',
                !isExpMobileLayout ? 'wf-pb-8' : 'wf-pb-4',
              )}>
              <div className="wf-flex wf-flex-col-reverse wf-items-center wf-gap-y-4 lg:wf-flex-col lg:wf-items-end">
                {carousel && (
                  <MediaCarousel
                    className="wf-h-[360px] wf-w-[270px] lg:wf-h-[460px] lg:wf-w-[340px]"
                    imageSwitchInterval={3000}
                    items={carousel.items}
                  />
                )}
                <div
                  className={cn(
                    'wf-flex wf-flex-row wf-justify-between wf-gap-x-2 wf-self-center',
                    mobileLayout === 'exp-v1-pills-and-top-rating' &&
                      'max-lg:wf-hidden',
                  )}>
                  <TopBannerFeatures
                    features={features}
                    mobileLayout={mobileLayout}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
