import * as React from 'react';

/**
 * @see {@link https://github.com/facebook/react/issues/14099#issuecomment-440013892}
 */
export function useEventCallback<T extends Function>(fn: T) {
  const ref = React.useRef<T>(fn);
  React.useLayoutEffect(() => {
    ref.current = fn;
  });

  // @ts-ignore
  return React.useCallback<T>((...args) => (0, ref.current)(...args), []);
}
