'use client';

import * as React from 'react';
import { throttle } from 'lodash';
import { IconButton } from '@wearemotivated/design-system/redesign/components/IconButton';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';
import { VideoTestimonialCard } from './VideoTestimonialCard';
import { TestimonialCard } from './TestimonialCard';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { TestimonialsDataType } from '@/redesign/strapi-blocks/landing-pages/Testimonials';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { Layout } from '@strapi-schema';

type VideoTestimonialListProps = {
  items: TestimonialsDataType['items'];
  disclaimerText?: string | null;
  layout?: Layout | string;
  /**
   * this is oversimpification but works well enough
   * we could check the container width in real time if needed
   */
  containerWidth: number;
};

export const VideoTestimonialList = ({
  items,
  disclaimerText,
  layout,
  containerWidth,
}: VideoTestimonialListProps) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [curIndex, setCurIndex] = React.useState<number>(0);
  const [visibleItems, setVisibleItems] = React.useState<number>(0);
  const [isNavVisible, setNavVisible] = React.useState<boolean>(false);
  const [stepWidth, setStepWidth] = React.useState<number>(0);
  const [playingId, setPlayingId] = React.useState<number | null>(null);
  const [touchStartX, setTouchStartX] = React.useState<number | null>(null);
  const [touchStartY, setTouchStartY] = React.useState<number | null>(null);
  const [isSwiping, setIsSwiping] = React.useState<boolean>(false);
  const [maxScroll, setMaxScroll] = React.useState<number>(
    Number.POSITIVE_INFINITY,
  );

  const HandlePrev = () => {
    setCurIndex((prev) => Math.max(0, prev - 1));
  };

  const HandleNext = () => {
    setCurIndex((prev) => (prev + visibleItems >= items.length ? 0 : prev + 1));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null && touchStartY !== null) {
      const deltaX = e.touches[0].clientX - touchStartX;
      const deltaY = e.touches[0].clientY - touchStartY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        e.preventDefault();
        setIsSwiping(true);
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (touchStartX !== null && touchStartY !== null && isSwiping) {
      const deltaX = e.changedTouches[0].clientX - touchStartX;
      if (deltaX > 50) {
        HandlePrev();
      } else if (deltaX < -50) {
        HandleNext();
      }
    }
    setTouchStartX(null);
    setTouchStartY(null);
    setIsSwiping(false);
  };

  const handleToggleSound = (id: number) => {
    setPlayingId((prevId) => {
      if (prevId === id) {
        return null;
      }
      return id;
    });
  };

  React.useEffect(() => {
    const updateLayoutValues = throttle(() => {
      if (carouselRef.current) {
        const computedStyle = window.getComputedStyle(carouselRef.current);
        const gapWidth = parseFloat(computedStyle.columnGap) || 0;

        const firstChild = carouselRef.current.children[0] as HTMLDivElement;
        const childWidth = firstChild?.offsetWidth ?? 0;

        const containerWidthCalc = Math.min(
          window.innerWidth - 32,
          containerWidth,
        );
        const stepWidthCalc = childWidth + gapWidth / 2;
        const itemsVisibleCalc = Math.floor(containerWidthCalc / stepWidthCalc);
        setVisibleItems(itemsVisibleCalc);

        // console.log('updateLayoutValues', {
        //   childWidth,
        //   gapWidth,
        //   stepWidth: stepWidthCalc,
        //   carouselWidth: carouselRef.current?.offsetWidth,
        //   containerWidth: containerWidthCalc,
        //   itemsVisible: Math.floor(containerWidthCalc / stepWidthCalc),
        // });

        setStepWidth(childWidth + gapWidth);
        // math here is made simple, instead of calculating in real time we make the container width a constant
        // -32 is padding of the container when full width
        // TODO can calculate container width in real time if needed
        setNavVisible(carouselRef.current?.offsetWidth! > containerWidthCalc);
        setMaxScroll(
          carouselRef.current.scrollWidth -
            carouselRef.current.parentElement?.clientWidth! +
            gapWidth,
        );
      }
    }, 300);

    updateLayoutValues();

    window.addEventListener('resize', updateLayoutValues);

    return () => window.removeEventListener('resize', updateLayoutValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wf-relative">
      <div className="wf-relative wf-flex wf-overflow-x-hidden">
        <div
          ref={carouselRef}
          className={cn(
            'wf-relative wf-flex wf-w-fit wf-transition-transform wf-duration-500',
            layout !== 'mixed-4-columns'
              ? 'wf-gap-x-4 lg:wf-gap-x-[4.5rem]'
              : 'wf-gap-x-2.5',
          )}
          style={{
            transform: `translateX(-${Math.min(curIndex * stepWidth, maxScroll)}px)`,
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}>
          {items.map((testimonial, index) => (
            <div
              key={`${testimonial.id}_${index}`}
              className="wf-w-[16rem] wf-shrink-0 sm:wf-w-[16.625rem] lg:wf-w-[21rem]">
              {layout === 'mixed-4-columns' && !testimonial.video ? (
                <div className="wf-h-[22.32rem] wf-w-full sm:wf-h-[22.875rem] lg:wf-h-[29.3rem]">
                  <TestimonialCard data={testimonial} layout={layout} />
                </div>
              ) : (
                <VideoTestimonialCard
                  data={testimonial}
                  isPlaying={testimonial.id === playingId}
                  classNames={{
                    videoContainer:
                      'wf-h-[22.32rem] sm:wf-h-[22.875rem] lg:wf-h-[29.3rem]',
                    ...(layout === 'mixed-4-columns' && { quote: 'wf-hidden' }),
                  }}
                  onTogglePlaying={() => handleToggleSound(testimonial.id)}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {isNavVisible ? (
        <div
          className={cn(
            'wf-relative wf-flex wf-w-full wf-flex-col-reverse wf-items-start wf-justify-start sm:wf-flex-row sm:wf-items-center sm:wf-justify-end',
          )}>
          {disclaimerText && (
            <p className="mr-auto wf-pr-8 wf-pt-7 wf-text-rdsn-body-sm wf-text-rdsn-black sm:wf-whitespace-pre-line sm:wf-pr-0 sm:wf-pt-5">
              {disclaimerText}
            </p>
          )}
          <div
            className={cn(
              'wf-flex wf-gap-4 wf-pt-7 sm:wf-px-6',
              layout !== 'mixed-4-columns' ? 'sm:wf-pt-5' : 'sm:wf-pt-6',
            )}>
            <IconButton
              variant="secondary"
              aria-label="back"
              onClick={HandlePrev}
              disabled={curIndex === 0}
              className="wf-bg-rdsn-white">
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              variant="secondary"
              aria-label="next"
              onClick={HandleNext}
              disabled={visibleItems + curIndex === items.length}
              className="wf-bg-rdsn-white">
              <ArrowRightIcon />
            </IconButton>
          </div>
        </div>
      ) : null}
    </div>
  );
};
