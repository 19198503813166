import { DosageType, ProductDataResponse } from '@/redesign/types';
import {
  ComponentsContainerData,
  ComponentsData,
  ProductDataType,
} from '@/redesign/api/strapi/types';

export function parseProductData(dataResponse: ProductDataResponse | null) {
  const dosages = new Map<string, DosageType>();
  let defaultDosage = null;
  const products = new Set<string>();

  if (
    !dataResponse ||
    !Array.isArray(dataResponse.products) ||
    !dataResponse.products.length
  ) {
    return { dosages, defaultDosage, products };
  }

  for (let product of dataResponse.products) {
    const productKey = product.attributes?.find(
      ({ key }) => key === 'pricingCalculatorName',
    )?.value;

    if (!product.variants || !productKey) {
      continue;
    }

    products.add(productKey);

    for (let dosage of product.variants) {
      if (!dosages.has(dosage.id)) {
        dosages.set(dosage.id, {
          id: dosage.id,
          isRecommended: dosage.isRecommended ?? false,
          numericalValue: dosage.numericalValue,
          products: new Map(),
        });
      }

      const currentDosage = dosages.get(dosage.id) as DosageType;
      if (!defaultDosage || dosage.default) {
        defaultDosage = currentDosage;
      }
      currentDosage.isRecommended =
        currentDosage.isRecommended ?? dosage.isRecommended;

      if (!currentDosage?.products.has(productKey)) {
        currentDosage?.products.set(productKey, {
          id: productKey,
          quantities: new Map(),
          categoryName: product.categoryName ?? '',
          manufacturerType: product.manufacturerType,
        });
      }

      if (!dosage.children) {
        continue;
      }

      for (let quantity of dosage.children) {
        if (
          !dosages
            .get(dosage.id)
            ?.products.get(productKey)
            ?.quantities.has(quantity.id)
        ) {
          dosages
            .get(dosage.id)
            ?.products.get(productKey)
            ?.quantities.set(quantity.id, {
              id: quantity.id,
              name: quantity.name,
              numericalValue: quantity.numericalValue ?? null,
              default: quantity.default ?? false,
              frequencies: new Map(),
            });
        }

        if (!quantity.children) {
          continue;
        }

        for (let frequency of quantity.children) {
          if (
            !dosages
              .get(dosage.id)
              ?.products.get(productKey)
              ?.quantities.get(quantity.id)
              ?.frequencies.has(frequency.id)
          ) {
            dosages
              .get(dosage.id)
              ?.products.get(productKey)
              ?.quantities.get(quantity.id)
              ?.frequencies.set(frequency.id, {
                id: frequency.id,
                name: frequency.name,
                default: frequency.default ?? false,
                totalPrice: frequency.totalPrice ?? null,
                numericalValue: frequency.numericalValue ?? null,
              });
          }
        }
      }
    }
  }

  return { dosages, defaultDosage, products };
}

export function normalizeProductComponents(
  components: ComponentsData,
  productData: ProductDataType,
): ComponentsData {
  for (let [componentId, componentData] of components) {
    if (componentId === 'topBannerProductPage') {
      (
        componentData as ComponentsContainerData['topBannerProductPage']
      ).safetyInformationUrl = productData.safetyInformationUrl;
    }
  }
  return components;
}
