'use client';

import { HeroWithQuestionsType } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import * as React from 'react';

export type QuestionProps = {
  index?: number;
  questions: HeroWithQuestionsType['questions'];
};

const Question = ({ index = 0, questions }: QuestionProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleLink = React.useCallback(() => {
    document.documentElement.style.setProperty('scroll-behavior', 'smooth');
    router.replace(
      questions.length === index + 1
        ? '#question-result'
        : `#question-${index + 2}`,
    );
  }, [index, questions.length, router]);

  React.useEffect(() => {
    document.documentElement.style.removeProperty('scroll-behavior');

    return () => {
      document.documentElement.style.removeProperty('scroll-behavior');
    };
  }, [pathname, searchParams]);

  return (
    <div
      id={`question-${index + 1}`}
      className={cn(
        'wf-flex wf-flex-col wf-items-center wf-justify-center',
        index > 0 && [
          'wf-inset-0 wf-h-screen wf-w-full',
          'wf-h-full wf-w-full',
          index % 2 === 0 ? 'wf-bg-rdsn-white' : 'wf-bg-rdsn-gray-100',
        ],
      )}>
      <div
        className={cn(
          'wf-flex wf-flex-col wf-items-center wf-justify-center wf-gap-10',
          index > 0 ? 'px-8 sm:px-0 wf-max-w-[400px]' : 'wf-max-w-[311px]',
        )}>
        <div className="wf-flex wf-flex-col wf-items-center wf-justify-center wf-gap-y-6">
          <div className="wf-flex wf-gap-2">
            {questions.map((_, _index) => (
              <div
                key={_index}
                className={cn(
                  'wf-h-8 wf-w-8',
                  'wf-rounded-full wf-border wf-border-rdsn-gray-200',
                  'wf-flex wf-items-center wf-justify-center',
                  'wf-font-gothic wf-text-rdsn-body-md wf-leading-none',
                  index === _index
                    ? 'wf-bg-rdsn-yellow wf-text-rdsn-black'
                    : 'wf-bg-rdsn-white wf-text-rdsn-gray-600',
                )}>
                <p>{_index + 1}</p>
              </div>
            ))}
          </div>
          <h2 className="wf-text-center wf-font-gothic wf-text-rdsn-h2 wf-leading-[1.1] wf-text-rdsn-black">
            {questions[index].title}
          </h2>
        </div>
        <div className="wf-flex wf-w-full wf-flex-col wf-gap-2">
          {questions[index].options.split('\n').map((opt) => (
            <Button key={opt} variant="primary" onClick={handleLink}>
              {opt}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Question;
