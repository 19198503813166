'use client';
import * as React from 'react';

import { MarkdownText } from '@wearemotivated/design-system/redesign/components/MarkdownText';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Accordion,
} from '@wearemotivated/design-system/redesign/components/Accordion';
import { Badge } from '@wearemotivated/design-system/redesign/components/Badge';
import ResponsiveImage from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { FaqContainerType } from '@/redesign/api/strapi/types';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { fixLocalImage } from '@/redesign/lib/utils/media';

export const FaqContainer = ({
  componentData: faq,
}: {
  componentData: FaqContainerType;
}) => {
  return (
    <div className="wf-relative wf-bg-rdsn-gray-100">
      <div className="mx-auto wf-relative wf-max-w-[1158px] wf-overflow-x-clip">
        {faq.layout !== 'center-aligned' && (
          <div
            className={cn(
              '-wf-bottom-[176px] -wf-left-[50px] sm:-wf-right-[28px] sm:wf-left-auto lg:-wf-left-[50px] lg:wf-right-auto',
              faq.image.mobile?.url ? 'wf-absolute sm:wf-block' : 'wf-hidden',
              faq.image.desktop.url
                ? 'lg:wf-absolute lg:wf-block'
                : 'lg:wf-hidden',
            )}>
            <ResponsiveImage
              {...fixLocalImage(faq.image)}
              loading="lazy"
              className="wf-block wf-h-[548px] wf-w-[548px]"
            />
          </div>
        )}
        <div
          className={cn(
            'wf-mx-auto wf-flex wf-min-h-[460px] wf-w-full wf-max-w-[508px] wf-flex-col wf-items-start wf-justify-between wf-gap-y-[30px] wf-px-4 sm:wf-items-center lg:wf-max-w-full',
            faq.layout !== 'center-aligned'
              ? 'wf-py-[4.5rem] lg:wf-flex-row lg:wf-items-start lg:wf-gap-y-0 lg:wf-py-24'
              : 'wf-py-24 lg:wf-gap-y-12 lg:wf-py-28',
            faq.image.mobile?.url && 'wf-pb-[350px]',
          )}>
          <div
            className={cn(
              'wf-flex wf-w-full wf-flex-col wf-gap-y-6 wf-px-4 wf-text-rdsn-black lg:wf-px-0',
              faq.layout !== 'center-aligned'
                ? 'sm:max-lg:wf-items-center lg:wf-w-1/2'
                : 'wf-items-center',
            )}>
            {faq.label && (
              <div>
                <Badge>{faq.label}</Badge>
              </div>
            )}
            <h2
              className={cn(
                'wf-whitespace-pre-line wf-font-gothic wf-text-rdsn-h1',
                faq.layout !== 'center-aligned'
                  ? 'sm:max-lg:wf-text-center max-lg:[&]:wf-text-[1.75rem]'
                  : 'wf-text-center [&]:wf-text-[1.75rem]',
              )}>
              {faq.title}
            </h2>
            <p
              className={cn(
                'wf-max-w-[250px] wf-font-gothic wf-text-rdsn-body',
                faq.layout !== 'center-aligned'
                  ? 'sm:max-lg:wf-text-center lg:wf-text-rdsn-body-lg'
                  : 'wf-mx-auto wf-text-center md:wf-max-w-xs',
              )}>
              {faq.description}
            </p>
          </div>
          <Accordion
            className={cn(
              'wf-w-full wf-rounded wf-bg-rdsn-white wf-px-4 wf-py-8 wf-text-rdsn-black lg:wf-max-w-[571px] lg:wf-px-8',
              faq.layout !== 'center-aligned' && 'lg:wf-mt-12',
            )}
            defaultValue={String(faq.items[0].id)}>
            {faq.items.map((faqEntry) => (
              <AccordionItem key={faqEntry.id} value={String(faqEntry.id)}>
                <AccordionTrigger>{faqEntry.question}</AccordionTrigger>
                <AccordionContent className="accordion-content">
                  <MarkdownText className="wf-text-rdsn-body-sm [&]:wf-text-[0.875rem]">
                    {faqEntry.answer ?? ''}
                  </MarkdownText>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};
