import Link from 'next/link';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import type { ProductDimension } from '@strapi-schema';
import PricingCalculatorStrengthOptions from './PricingCalculatorStrengthOptions';
import PricingCalculatorProductOptions from './PricingCalculatorProductOptions';
import PricingCalculatorQuantitySelect from './PricingCalculatorQuantitySelect';
import PricingCalculatorFrequencySelect from './PricingCalculatorFrequencySelect';
import PricingCalculatorTotal from './PricingCalculatorTotal';
import { parseProductData } from '@/redesign/lib/utils/product';
import { type WizardCtaNormalizedType } from '@/app/api/strapi/types';
import { PricingCalculatorProvider } from '@/redesign/strapi-blocks/landing-pages/PricingCalculator/PricingCalculatorContext';
import { MarkdownText } from '@wearemotivated/design-system/redesign/components/MarkdownText';

export type PricingCalculatorFormProps = {
  productVariants: ReturnType<typeof parseProductData>;
  dimensionsTooltips?: Partial<Record<ProductDimension, string>>;
  cta: WizardCtaNormalizedType;
};

export default function PricingCalculatorForm({
  productVariants,
  dimensionsTooltips,
  cta,
}: PricingCalculatorFormProps) {
  return (
    <PricingCalculatorProvider productVariants={productVariants}>
      <form className="wf-mx-auto wf-max-w-md wf-rounded wf-bg-rdsn-white wf-px-4 wf-pb-6 wf-pt-5 md:wf-w-[22.5rem] lg:wf-p-6 lg:wf-pt-5">
        <div className="wf-space-y-6">
          <PricingCalculatorStrengthOptions
            tooltip={dimensionsTooltips?.['dosage']}
          />
          <PricingCalculatorProductOptions
            tooltip={dimensionsTooltips?.['manufacturerType']}
          />
          <PricingCalculatorQuantitySelect
            tooltip={dimensionsTooltips?.['quantity']}
          />
          <PricingCalculatorFrequencySelect
            tooltip={dimensionsTooltips?.['frequency']}
          />
        </div>
        <PricingCalculatorTotal productVariants={productVariants} />
        <Button
          size="xl"
          className="wf-w-full wf-gap-2 wf-bg-rdsn-black"
          asChild>
          <Link href={cta.link}>
            {cta.text} <ArrowRightIcon />
          </Link>
        </Button>
        {cta.disclaimerText && (
          <div className="wf-mt-4">
            <MarkdownText className="wf-text-center wf-text-rdsn-body-sm wf-text-rdsn-black">
              {cta.disclaimerText}
            </MarkdownText>
          </div>
        )}
      </form>
    </PricingCalculatorProvider>
  );
}
