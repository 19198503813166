import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { HeroItemClean, LandingTopBanner } from '@strapi-schema';

export const TopBannerFeatures = ({
  features,
  mobileLayout,
}: {
  features: HeroItemClean[];
  mobileLayout?: LandingTopBanner['mobileLayout'];
}) => {
  return (
    <>
      {features.map((item, index) => (
        <div
          key={index}
          className={cn(
            'wf-flex wf-flex-col wf-items-center wf-gap-y-1',
            mobileLayout === 'exp-v1-pills-and-top-rating' &&
              'wf-gap-x-2 max-lg:wf-flex-row',
          )}>
          <img
            alt={item.icon?.alt}
            src={item.icon?.url}
            className="wf-h-[15px] wf-w-auto"
          />
          <span
            className={cn(
              'wf-text-center wf-font-gothic wf-text-rdsn-prod-sub wf-text-rdsn-black',
              'exp-v1-pills-and-top-rating' === mobileLayout &&
                'max-lg:wf-relative max-lg:wf-top-[1px]',
            )}>
            {item.title}
          </span>
        </div>
      ))}
    </>
  );
};
