'use client';
import * as React from 'react';
import { TrackingAttributesNormalizedType } from '@/app/api/strapi/types';
import { Mixpanel, PAGE_VIEW_EVENT } from '@/app/utils/mixpanel';
import { useEventCallback } from '@/app/hooks/useEventCallback';

type RegisterPageViewProps = {
  trackingAttributes: TrackingAttributesNormalizedType;
};

/**
 * Reports page view to mixpanel
 * ATTENTION! Component mounts twice in react strict mode + dev
 */
export const RegisterPageView = ({
  trackingAttributes,
}: RegisterPageViewProps) => {
  const reportPageView = useEventCallback(() => {
    Mixpanel.track(PAGE_VIEW_EVENT, {
      name: trackingAttributes.name,
      // url is injected by mixpanel.track
      ...trackingAttributes.customAttributes,
    });
  });

  React.useEffect(() => {
    reportPageView();
  }, [reportPageView]);

  return null;
};
