'use client';
import ResponsiveImage from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import {
  Media,
  ResponsiveImageType,
} from '@wearemotivated/design-system/redesign/types';
import React, { useState, useEffect, useCallback, useRef } from 'react';

interface ResponsiveVideoType {
  desktop: Media;
  mobile?: Media;
}

export type MediaCarouselItem = {
  media: {
    image: ResponsiveImageType;
    video: ResponsiveVideoType;
  };
};

export type MediaCarouselProps = {
  items: MediaCarouselItem[];
  slug?: string;
  className?: string;
  imageSwitchInterval: number;
};

const GetMediaType = (item: MediaCarouselItem) => {
  if (item.media.image?.desktop?.url || item.media.image?.mobile?.url)
    return 'image';
  else if (item.media.video?.desktop?.url || item.media.video?.mobile?.url)
    return 'video';
};

const initialLoadingIntervalFactor = 5000;

const MediaCarousel = ({
  items,
  imageSwitchInterval,
  className = 'wf-h-[360px] wf-w-[270px] lg:wf-h-[460px] lg:wf-w-[340px]',
}: MediaCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animateOut, setAnimateOut] = useState(false);
  const [animatedIn, setAnimatedIn] = useState(false);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const rotateItems = useCallback(() => {
    const newActiveIndex = (activeIndex + 1) % items.length;
    setActiveIndex(newActiveIndex);
    setAnimateOut(false);
    setAnimatedIn(false);
  }, [activeIndex, items?.length]);

  const handleMediaEnd = useCallback(() => {
    setAnimateOut(true);
    setAnimatedIn(true);
    setTimeout(() => rotateItems(), 500);
  }, [rotateItems]);

  useEffect(() => {
    if (!items?.length) return;

    const currentMediaType = GetMediaType(items[activeIndex]);
    if (currentMediaType === 'image') {
      const timer = setTimeout(() => {
        setAnimateOut(true);
        setAnimatedIn(true);
        setTimeout(() => rotateItems(), 500);
      }, imageSwitchInterval - 500);

      return () => clearTimeout(timer);
    } else if (currentMediaType === 'video') {
      const videoDesktopElement = videoRefs.current[activeIndex * 2];
      const videoMobileElement = videoRefs.current[activeIndex * 2 + 1];

      if (videoDesktopElement) {
        videoDesktopElement.muted = true;
        videoDesktopElement.play();
        videoDesktopElement.addEventListener('ended', handleMediaEnd);
      }

      if (videoMobileElement) {
        videoMobileElement.muted = true;
        videoMobileElement.play();
        videoMobileElement.addEventListener('ended', handleMediaEnd);
      }

      return () => {
        videoDesktopElement?.removeEventListener('ended', handleMediaEnd);
        videoMobileElement?.removeEventListener('ended', handleMediaEnd);
      };
    }
  }, [activeIndex, imageSwitchInterval, items, handleMediaEnd, rotateItems]);

  return (
    <div className={cn('wf-relative wf-overflow-hidden', className)}>
      {items?.map((item, index) => {
        const isActive = index === activeIndex;
        return (
          <div
            key={index}
            className="wf-absolute wf-inset-6"
            style={{
              transform: `rotate(${Math.min(index, 6) * 1}deg)`,
              zIndex: items.length - index,
            }}>
            <div
              className={cn(
                `wf-h-full wf-w-full wf-transition-opacity wf-duration-500`,
                index < activeIndex && 'wf-translate-x-full wf-opacity-0',
                animateOut && isActive && activeIndex < items.length - 1
                  ? 'slide-out-right'
                  : '',
                animatedIn &&
                  activeIndex === items.length - 1 &&
                  activeIndex !== index
                  ? 'slide-in-left'
                  : '',
              )}>
              {GetMediaType(item) === 'video' ? (
                <>
                  <video
                    ref={(el) => (videoRefs.current[index * 2] = el) as any}
                    id={`media-carousel-${index}`}
                    className="wf-hidden wf-h-full wf-w-full wf-rounded wf-object-cover lg:wf-block"
                    autoPlay={isActive}
                    muted
                    playsInline
                    preload="auto">
                    <source
                      src={item.media.video.desktop.url}
                      type="video/mp4"
                    />
                  </video>
                  <video
                    ref={(el) => (videoRefs.current[index * 2 + 1] = el) as any}
                    id={`media-carousel-mobile-${index}`}
                    className="wf-h-full wf-w-full wf-rounded wf-object-cover lg:wf-hidden"
                    autoPlay={isActive}
                    muted
                    playsInline
                    preload="auto">
                    <source
                      src={
                        item.media.video.mobile?.url ||
                        item.media.video.desktop.url
                      }
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <ResponsiveImage
                  className="wf-h-full wf-w-full wf-rounded wf-object-cover"
                  {...item.media.image}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MediaCarousel;
