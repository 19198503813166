import PricingCalculatorRadioGroup from './PricingCalculatorRadioGroup';
import {
  type ProductOption,
  usePricingCalculator,
} from '../PricingCalculatorContext';
import { DefaultOptionLabelComponent } from '@wearemotivated/design-system/redesign/components/RadioGroup';
import { Badge } from '@wearemotivated/design-system/redesign/components/Badge';

type PricingCalculatorProductOptionsProps = {
  tooltip?: string;
};

type OptionLabelProps = {
  option: ProductOption;
  optionLabelClassName?: string;
};

export default function PricingCalculatorProductOptions({
  tooltip,
}: PricingCalculatorProductOptionsProps) {
  const { values, options, dispatchCalculatorEvent } = usePricingCalculator();

  const handleChange = (v: ProductOption) => {
    dispatchCalculatorEvent({
      action: 'PRODUCT_CHANGE',
      data: v,
    });
  };

  return (
    <PricingCalculatorRadioGroup
      title="Generic or Branded"
      tooltip={tooltip}
      options={options.product}
      value={values.product}
      RenderOptionLabelComponent={ProductOptionLabel}
      onChange={handleChange}
    />
  );
}

const ProductOptionLabel = ({
  option,
  optionLabelClassName,
}: OptionLabelProps) => {
  return (
    <>
      <DefaultOptionLabelComponent
        option={option}
        optionLabelClassName={optionLabelClassName}
      />
      {option.manufacturerType === 'generic' && (
        <Badge className="wf-absolute wf-right-0 wf-top-0 wf-rounded-md wf-rounded-tl-none wf-py-1.5">
          Most popular
        </Badge>
      )}
    </>
  );
};
