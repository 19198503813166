'use client';
import { type CSSProperties, type ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import { DesktopCardsLayoutMobileListItemUnstructuredContent } from '@strapi-schema';
import { cn } from '@wearemotivated/design-system/lib/utils';

type DesktopCardsLayoutMobileListCardContainerProps = {
  data?: DesktopCardsLayoutMobileListItemUnstructuredContent;
  children: ReactNode;
};

export const DesktopCardsLayoutMobileListCardContainer = ({
  data,
  children,
}: DesktopCardsLayoutMobileListCardContainerProps) => {
  const router = useRouter();
  const handleClick = () => {
    if (data?.button?.url) {
      router.push(data?.button.url);
    }
  };

  let paddingClass;
  let paddingRightClass;

  if (data?.padding) {
    paddingClass = `wf-p-[var(--padding)]`;
  }
  if (data?.paddingRight) {
    paddingRightClass = `wf-pl-4 wf-pr-${data?.paddingRight}`;
  }
  if (data?.type === 'lg-card') {
    paddingClass = `sm:wf-pl-8  ${paddingRightClass || 'sm:wf-pr-6'} lg:wf-pt-12`;
  } else if (data?.type === 'md-card') {
    paddingClass = `sm:wf-py-2.5 sm:wf-pl-8 ${paddingRightClass || 'sm:wf-pr-3'}`;
  }

  return (
    <div
      className={cn(
        'wf-overflow-hidden wf-rounded wf-border wf-border-rdsn-gray-400 wf-bg-[var(--bg-color)] hover:wf-cursor-pointer hover:wf-border-rdsn-black hover:wf-bg-rdsn-gray-100',
        data?.type === 'lg-card'
          ? 'lg:wf-w-[var(--container-width)]'
          : 'sm:wf-w-[var(--container-width)]',
        data?.type === 'md-image-card' && 'sm:max-lg:wf-self-start',
      )}
      style={
        {
          '--bg-color': data?.backgroundColor,
          '--container-width': data?.width ?? 'auto',
          '--padding': data?.padding,
          '--image-col-width': data?.imageColumnWidth,
        } as CSSProperties
      }
      onClick={handleClick}>
      <div
        className={cn(
          data?.type === 'lg-card' &&
            (paddingRightClass || 'max-sm:wf-px-4') +
              ' max-sm:wf-pt-6 sm:wf-grid sm:wf-h-full sm:wf-grid-cols-[1fr_minmax(auto,var(--image-col-width,15.5rem))] sm:wf-grid-rows-[auto,1rem,auto,1.5rem,1fr,1.5rem,auto,2rem] sm:wf-gap-x-4',
          data?.type === 'md-card' &&
            'max-sm:wf-pb-5 max-sm:wf-pt-6 sm:wf-grid sm:wf-grid-cols-[1fr_minmax(0,var(--image-col-width,21.75rem))] sm:wf-grid-rows-[2.5rem,auto,1rem,auto,1.5rem,1fr,1.5rem,auto,1rem] sm:wf-gap-x-9 sm:wf-py-2.5 sm:wf-pl-8 sm:wf-pr-3',
          data?.type === 'md-image-card' && 'wf-grid lg:wf-h-full lg:wf-w-full',
          paddingClass,
        )}>
        {children}
      </div>
    </div>
  );
};
