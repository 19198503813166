'use client';
import { useEffect, useState } from 'react';
import { parseProductData } from '@/redesign/lib/utils/product';
import PricingCalculatorForm from './form';
import { type ProductDimension } from '@strapi-schema';
import type { WizardCtaNormalizedType } from '@/app/api/strapi/types';
import { fetchProductsByIds } from '@/redesign/lib/actions';

type PricingCalculatorProps = {
  productIds: string[];
  dimensionsTooltips: Record<ProductDimension, string>;
  cta: WizardCtaNormalizedType;
};

export default function PricingCalculator({
  productIds,
  dimensionsTooltips,
  cta,
}: PricingCalculatorProps) {
  const [productVariants, setProductVariants] =
    useState<ReturnType<typeof parseProductData>>();
  useEffect(() => {
    (async () => {
      const variantsRaw = await fetchProductsByIds(productIds);
      const variants = parseProductData(variantsRaw);
      setProductVariants(variants);
    })();
  }, [productIds]);

  if (
    !productVariants ||
    !productVariants.dosages?.size ||
    !productVariants.products?.size
  )
    return null;

  return (
    <PricingCalculatorForm
      productVariants={productVariants}
      dimensionsTooltips={dimensionsTooltips}
      cta={cta}
    />
  );
}
