export async function lookupZip(params: { zipcode: string }) {
  const res = await fetch('/api/rdsn/survey', {
    method: 'POST',
    body: JSON.stringify({
      queryId: 'ZIP_CODE_LOOKUP',
      ...params,
    }),
  });

  if (!res.ok) {
    return null;
  }

  try {
    const json = await res.json();

    return json;
  } catch (e) {
    console.error('lookupZip error', e);
    return null;
  }
}

export async function fetchProductsByIds(productIds: string[]) {
  const res = await fetch('/api/rdsn/product', {
    method: 'POST',
    body: JSON.stringify({
      queryId: 'FETCH_PRODUCTS_BY_IDS',
      productIds: productIds.join(','),
    }),
  });

  if (!res.ok) {
    return null;
  }

  try {
    const json = await res.json();

    return json;
  } catch (e) {
    console.error('fetchProductsByIds error', e);
    return null;
  }
}
