import PricingCalculatorRadioGroup from './PricingCalculatorRadioGroup';
import { DefaultOptionLabelComponent } from '@wearemotivated/design-system/redesign/components/RadioGroup';
import {
  type StrengthOption,
  usePricingCalculator,
} from '../PricingCalculatorContext';

type PricingCalculatorStrengthOptionsProps = {
  tooltip?: string;
};

type OptionLabelProps = {
  option: StrengthOption;
  optionLabelClassName?: string;
};

export default function PricingCalculatorStrengthOptions({
  tooltip,
}: PricingCalculatorStrengthOptionsProps) {
  const { values, options, dispatchCalculatorEvent } = usePricingCalculator();

  const handleChange = (v: StrengthOption) => {
    dispatchCalculatorEvent({
      action: 'STRENGTH_CHANGE',
      data: v,
    });
  };

  return (
    <PricingCalculatorRadioGroup
      title="Strength"
      tooltip={tooltip}
      options={options.strength}
      value={values.strength}
      RenderOptionLabelComponent={StrengthOptionLabel}
      onChange={handleChange}
    />
  );
}

const StrengthOptionLabel = ({
  option,
  optionLabelClassName,
}: OptionLabelProps) => {
  return (
    <div className="wf-flex wf-w-full wf-items-center wf-justify-between">
      <DefaultOptionLabelComponent
        option={option}
        optionLabelClassName={optionLabelClassName}
      />
      <div className="wf-text-rdsn-prod-sub wf-text-rdsn-black">
        {option.category}
      </div>
    </div>
  );
};
