import { type ChangeEvent } from 'react';
import { Select } from '@wearemotivated/design-system/redesign/components/Select';
import PricingCalculatorFormField from './PricingCalculatorFormField';
import { usePricingCalculator } from '../PricingCalculatorContext';

type PricingCalculatorFrequencySelectProps = {
  tooltip?: string;
};

export default function PricingCalculatorFrequencySelect({
  tooltip,
}: PricingCalculatorFrequencySelectProps) {
  const { values, options, dispatchCalculatorEvent } = usePricingCalculator();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatchCalculatorEvent({
      action: 'FREQUENCY_CHANGE',
      data: e.target.value,
    });
  };

  return (
    <PricingCalculatorFormField title="Frequency" tooltip={tooltip}>
      <Select
        options={options.frequency}
        value={values.frequency?.value}
        className="wf-py-5"
        onChange={handleChange}
      />
    </PricingCalculatorFormField>
  );
}
