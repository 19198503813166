'use client';
import * as React from 'react';
import {
  Header as RedesignHeader,
  HeaderProps as RedesignHeaderProps,
} from '@wearemotivated/design-system/redesign/components/Header';
import { ProfileIcon } from '@wearemotivated/design-system/redesign/icons/Profile';
import { MenuIcon } from '@wearemotivated/design-system/redesign/icons/Menu';
import { CrossIcon } from '@wearemotivated/design-system/redesign/icons/Cross';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { HeaderDataType } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import Link from 'next/link';
import { MotivatedLogo } from '@wearemotivated/design-system/redesign/logos/Motivated';
import { usePathname } from 'next/navigation';
import { fixLocalUrl } from '@/redesign/lib/utils/media';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { useTailwindBreakpoint } from '@wearemotivated/design-system/redesign/hooks/useTailwindBreakpoint';
import { useIsLoggedIn } from '@/redesign/components/hooks/useIsLoggedIn';
import { ACCOUNT_URL, LOGIN_URL } from '@/redesign/lib/constants';

type HeaderMenuProps = RedesignHeaderProps & {
  headerData: HeaderDataType;
};

type MobileMenuStateType = 'closed' | 'open-group' | 'open-items';

// TODO not using props
export const HeaderWithMenu = ({ headerData }: HeaderMenuProps) => {
  const [mobileState, setMobileState] =
    React.useState<MobileMenuStateType>('open-group');
  const [activeMenuId, setActiveMenuId] = React.useState<string | null>(null);
  const pathname = usePathname();
  const isDesktop = useTailwindBreakpoint('sm', true);
  const { isLoggedIn } = useIsLoggedIn();

  const menuItems =
    activeMenuId !== null
      ? headerData.navigationButtons.find((item) => item.id === activeMenuId)
          ?.items
      : null;

  const isMobileOpen = mobileState !== 'closed';

  // console.log('getRedesignHeader', JSON.stringify(headerData, null, 2));

  const handleOpenMenu = () => {
    setMobileState('open-group');
  };

  const handleCloseMenu = () => {
    setMobileState('closed');
  };

  const handleBackMenu = () => {
    setMobileState('open-group');
  };

  const handleSubMenuMobile = (id: string) => {
    const menuItems = headerData.navigationButtons.find(
      (item) => item.id === id,
    );
    if (!menuItems) {
      return;
    }
    setMobileState('open-items');
    setActiveMenuId(id);
  };

  const toggleSubMenu = (id: string) => {
    if (activeMenuId === id) {
      setActiveMenuId(null);
      return;
    }

    const menuItems = headerData.navigationButtons.find(
      (item) => item.id === id,
    );
    if (!menuItems) {
      return;
    }
    setActiveMenuId(id);
  };

  const rightContent = headerData.navigationButtons.map((item) => {
    return (
      <React.Fragment key={item.id}>
        <Button variant="menuItem" onClick={() => toggleSubMenu(item.id)}>
          {item.root?.text}
        </Button>
        {item.id === activeMenuId ? (
          /* -1px top to overlap border */
          <div className="wf-absolute wf-left-0 wf-right-0 wf-top-[63px] wf-z-[900] wf-flex wf-w-full wf-flex-col wf-items-stretch wf-gap-y-4 wf-overflow-x-clip wf-border wf-border-rdsn-gray-400 wf-bg-rdsn-white wf-py-6 wf-font-gothic wf-text-rdsn-black wf-shadow-[0_4px_8px_0_rgba(0,0,0,0.09)] max-sm:wf-hidden">
            <div className="wf-mb-10 wf-grid wf-w-full wf-auto-cols-fr wf-grid-flow-col sm:wf-gap-x-4 sm:wf-px-4 md:wf-gap-x-12 md:wf-px-[56px] xl:wf-gap-x-32 xl:wf-px-[140px]">
              {!!menuItems &&
                menuItems?.map((subMenu) => {
                  return (
                    <div
                      key={subMenu.id}
                      className="wf-flex wf-w-full wf-flex-col wf-gap-y-6 wf-px-2">
                      <div className="wf-text-rdsn-body-sm">
                        {subMenu.root?.text}
                      </div>
                      <div className="wf-grid wf-grid-cols-[repeat(2,_minmax(0,_240px))] wf-gap-x-2 wf-gap-y-1">
                        {subMenu?.items?.map((item) => {
                          const iconUrl = item.iconUrl || item.icon?.url;

                          return (
                            <div
                              key={item.id}
                              className={cn('-wf-mx-4 wf-flex wf-w-full')}>
                              <Button
                                className="wf-flex-row wf-justify-start"
                                variant="menuItem"
                                size="xl"
                                asChild>
                                <Link href={item.url ?? ''}>
                                  {item.text}
                                  {iconUrl ? (
                                    <img
                                      className="wf-h-[40px] wf-w-auto"
                                      src={fixLocalUrl(iconUrl, 'image')}
                                      alt={item?.icon?.alt ?? ''}
                                    />
                                  ) : null}
                                </Link>
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  });

  // prevents document scrolling when mobile menu is open
  React.useEffect(() => {
    if (isMobileOpen) {
      document.body.classList.add('mv-no-overflow');
    }
    if (!isMobileOpen) {
      document.body.classList.remove('mv-no-overflow');
    }

    return () => {
      document.body.classList.remove('mv-no-overflow');
    };
  }, [isMobileOpen]);

  // resets mobile menu state on screen resize
  React.useEffect(() => {
    if (isDesktop) {
      setMobileState('closed');
    }
  }, [isDesktop]);

  // closes mobile menu on navigation
  React.useEffect(() => {
    setMobileState('closed');
  }, [pathname]);

  return (
    <>
      <RedesignHeader
        className={cn(
          'wf-border-b wf-border-b-rdsn-subtleBorder',
          mobileState === 'open-group' && 'wf-border-b-0',
        )}
        left={
          <span className="wf-ml-6 wf-inline-flex wf-items-center">
            <span className="wf-contents sm:wf-hidden">
              {!isMobileOpen ? (
                <Button
                  className="wf-size-[32px] wf-p-0"
                  variant="outlined"
                  onClick={handleOpenMenu}>
                  <MenuIcon className="wf-h-[18px] wf-w-auto" />
                </Button>
              ) : null}
              {mobileState === 'open-group' ? (
                <Button
                  className="wf-size-[32px] wf-p-0"
                  variant="outlined"
                  onClick={handleCloseMenu}>
                  <CrossIcon />
                </Button>
              ) : null}
              {mobileState === 'open-items' ? (
                <Button
                  className="wf-size-[32px] wf-p-0"
                  variant="outlined"
                  onClick={handleBackMenu}>
                  <ArrowLeftIcon />
                </Button>
              ) : null}
            </span>
            <Link href="/" className="max-sm:wf-hidden" aria-label="homepage">
              <MotivatedLogo className="block h-5 w-auto" />
            </Link>
          </span>
        }
        center={
          <Link href="/" className="sm:wf-hidden" aria-label="homepage">
            <MotivatedLogo className="block h-5 w-auto" />
          </Link>
        }
        right={
          <span className="wf-mr-6 wf-inline-flex wf-items-center wf-gap-x-5">
            <span className="wf-contents max-sm:wf-hidden">{rightContent}</span>
            {isLoggedIn ? (
              <Button variant="outlined" asChild>
                <a href={ACCOUNT_URL}>Account</a>
              </Button>
            ) : (
              <Button
                className="wf-size-[32px] wf-px-0"
                variant="outlined"
                aria-label="sign in"
                asChild>
                <a href={LOGIN_URL}>
                  <ProfileIcon />
                </a>
              </Button>
            )}
          </span>
        }
      />
      {isMobileOpen ? (
        <nav className="wf-fixed wf-inset-0 wf-top-[56px] wf-z-[900] wf-flex wf-flex-col wf-items-stretch wf-gap-y-4 wf-overflow-y-auto wf-overflow-x-clip wf-bg-rdsn-white wf-px-3 wf-py-6 wf-font-gothic wf-text-rdsn-black sm:wf-hidden">
          {mobileState === 'open-group' &&
            headerData.navigationButtons.map((item) => {
              return (
                <Button
                  key={item.id}
                  className="px-2 wf-flex wf-h-[27px] wf-w-full wf-flex-row wf-items-center wf-justify-between wf-rounded-none wf-text-rdsn-body hover:wf-bg-rdsn-gray-100"
                  variant="unstyled"
                  onClick={() => handleSubMenuMobile(item.id)}>
                  <span>{item.root?.text}</span>
                  <ArrowRightIcon />
                </Button>
              );
            })}
          {mobileState === 'open-items' &&
            !!menuItems &&
            menuItems?.map((subMenu) => {
              return (
                <div
                  key={subMenu.id}
                  className="wf-mb-20 wf-flex wf-w-full wf-flex-col wf-gap-y-6 wf-px-2">
                  <div className="wf-text-rdsn-body-sm">
                    {subMenu.root?.text}
                  </div>
                  {subMenu?.items?.map((item) => {
                    const iconUrl = item.iconUrl || item.icon?.url;

                    return (
                      <div
                        key={item.id}
                        className={cn(
                          '-mx-4 wf-flex wf-w-full wf-items-center',
                        )}>
                        <Button
                          className="wf-flex-row wf-justify-start"
                          variant="menuItem"
                          size="xl"
                          asChild>
                          <Link href={item.url ?? ''}>
                            {item.text}
                            {iconUrl ? (
                              <img
                                className="wf-h-[40px] wf-w-auto"
                                src={fixLocalUrl(iconUrl, 'image')}
                                alt={item?.icon?.alt ?? ''}
                              />
                            ) : null}
                          </Link>
                        </Button>
                        {!iconUrl ? (
                          <ArrowRightIcon className="wf-ml-auto" />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </nav>
      ) : null}
    </>
  );
};
