import { type ChangeEvent } from 'react';
import { Select } from '@wearemotivated/design-system/redesign/components/Select';
import PricingCalculatorFormField from './PricingCalculatorFormField';
import { usePricingCalculator } from '../PricingCalculatorContext';

type PricingCalculatorQuantitySelectProps = {
  tooltip?: string;
};

export default function PricingCalculatorQuantitySelect({
  tooltip,
}: PricingCalculatorQuantitySelectProps) {
  const { values, options, dispatchCalculatorEvent } = usePricingCalculator();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatchCalculatorEvent({
      action: 'QUANTITY_CHANGE',
      data: e.target.value,
    });
  };

  return (
    <PricingCalculatorFormField title="Quantity" tooltip={tooltip}>
      <Select
        options={options.quantity}
        value={values.quantity?.value}
        className="wf-py-5"
        onChange={handleChange}
      />
    </PricingCalculatorFormField>
  );
}
